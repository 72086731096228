const DemoVideo = () => {
  return (
    <div className="rounded-xl w-full max-w-5xl p-0.5 drop-shadow-[8px_8px_170px_#547AFF4A] bg-gradient-to-r from-[#FF00FF] to-[#FE511B]">
      <video
        className="aspect-[16/9] w-full rounded-xl"
        controls
        muted
        loop
        preload="none"
        poster="/assets/images/demo-thumb.jpg"
      >
        <source src="/assets/videos/demo.mp4" type="video/mp4" />
      </video>
    </div>
  );
};

export default DemoVideo;
