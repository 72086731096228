import OutLineButuon from 'components/common/OutLineButuon';
import { useRouter } from 'next/router';
import { useAccount } from 'wagmi';

const WhyRevokeCash = () => {
  const router = useRouter();

  const { address } = useAccount();

  const handleClick = () => {
    if (address) {
      router.push(`/address/${address}?chainId=369`);
    }
  };

  return (
    // bg-gradient-to-r from-[#ff00ff] via-[#823aa3] to-[#100295]  dark:from-[#69eacb] dark:via-[#eaccf8] dark:to-[#6654f1]
    <div className="w-full px-4 py-10">
      <div className="flex flex-col gap-4 md:gap-4 max-w-5xl mx-auto justify-center items-center">
        <h1 className="text-4xl md:text-5xl max-w-xl mb-4 text-transparent color_text_1 text-center">
          Why You Should Use Banditproof?
        </h1>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col md:flex-row justify-between">
            <div className="flex flex-col w-full md:w-2/3 justify-center items-center md:items-start max-w-auto md:max-w-xl">
              <h2 className="w-full text-3xl max-w-[400px] mb-4 text-center md:text-left">
                Use Banditproof periodically.
              </h2>
              <p className="text-[#00000099] dark:text-[#8F9BB7] mb-4 text-center md:text-left">
                It is always good to limit your approvals whenever you are not actively using a dapp, especially for NFT
                marketplaces. This reduces the risk of losing your funds to hacks or exploits and can also help mitigate
                the damage of phishing scams.
              </p>

              <OutLineButuon onClick={handleClick} size="md" children={'Get Started'} router />
            </div>
            <div className="flex-col w-full md:w-1/3 max-w-[400px]">
              <img src="/assets/images/why_01.png" alt="" />
            </div>
          </div>

          <div className="flex flex-col-reverse md:flex-row justify-between">
            <div className="flex-col w-full md:w-1/3 max-w-[400px]">
              <img src="/assets/images/why_02.png" alt="" />
            </div>
            <div className="flex flex-col w-full md:w-2/3 justify-center  items-center md:items-start max-w-xl">
              <h2 className="w-full text-3xl max-w-[400px] mb-4 text-center md:text-left">
                Use Banditproof after getting scammed.
              </h2>
              <p className="text-[#00000099] dark:text-[#8F9BB7] mb-4 text-center md:text-left">
                Very often, scammers try to trick you into granting them an approval to your funds. Sort your approvals
                by most recent to find out which approvals are to blame and revoke them to prevent further damage.
                Unfortunately it is not possible to recover funds that have already been stolen.
              </p>
              <OutLineButuon onClick={handleClick} size="md" children={'Get Started'} router />
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between">
            <div className="flex flex-col w-full md:w-2/3 justify-center items-center md:items-start max-w-xl">
              <h2 className="w-full text-3xl max-w-[400px] mb-4 text-center md:text-left">
                Use the Banditproof browser extension.
              </h2>
              <p className="text-[#00000099] dark:text-[#8F9BB7] mb-4 text-center md:text-left">
                Prevention is better than mitigation. The Banditproof browser extension warns you when you're about to
                sign something potentially harmful. This can save you from phishing scams by making you think twice
                about what you're doing.
              </p>
              <OutLineButuon onClick={handleClick} size="md" children={'Get Started'} router />
            </div>
            <div className="flex-col w-full md:w-1/3 max-w-[400px]">
              <img src="/assets/images/why_03.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyRevokeCash;
