import { useColorTheme } from 'lib/hooks/useColorTheme';
import Link from 'next/link';
import { MouseEventHandler } from 'react';
import { twMerge } from 'tailwind-merge';
import Spinner from './Spinner';

// TODO: Proper extended ty[es for this component]
interface Props extends Record<string, any> {
  disabled?: boolean;
  size: 'sm' | 'md' | 'lg' | 'none' | 'menu';
  onClick?: MouseEventHandler;
  href?: string;
  children: React.ReactNode;
  className?: string;
  external?: boolean;
  router?: boolean;
  loading?: boolean;
  asDiv?: boolean;
  align?: 'left' | 'center' | 'right';
}

const OutLineButuon = ({
  disabled,
  style,
  size,
  onClick,
  href,
  external,
  router,
  children,
  className,
  loading,
  asDiv,
  align,
  ...props
}: Props) => {
  const { darkMode } = useColorTheme();

  // In dark mode, we swap the primary and secondary styles
  const variant = style === 'secondary' && darkMode ? 'primary' : style === 'primary' && darkMode ? 'secondary' : style;

  const classMapping = {
    common:
      'flex items-center border-0 duration-150 cursor-pointer disabled:cursor-not-allowed leading-none font-medium shrink-0 whitespace-nowrap',
    menu: 'h-9 px-4 border-none font-normal text-base justify-start',
    sm: 'h-6 px-2 text-xs ',
    md: 'h-11 px-6 text-base ',
    lg: 'h-12 px-7 text-lg ',
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
  };

  const wrapperclasses = twMerge('rounded-[50px] p-0.5 bg-gradient-to-r from-[#FF00FF] to-[#FE511B] ');
  const contentclasses = twMerge(
    'rounded-[50px] bg-[#fff] dark:bg-[#060714] hover:bg-transparent outline_btn',
    classMapping.common,
    classMapping[variant],
    classMapping[align ?? 'center'],
    size !== 'none' && classMapping[size],
    loading && 'flex gap-1',
    className
  );
  // Note: This code is repeated in Href.tsx for styling reasons
  if (href) {
    if (router) {
      return external ? (
        <a {...props} className={wrapperclasses} href={href} target={external ? '_blank' : undefined}>
          <div className={contentclasses}>
            <p className="color_text_2">{children}</p>
          </div>
        </a>
      ) : (
        <Link {...props} className={wrapperclasses} href={href}>
          <div className={contentclasses}>
            <p className="color_text_2">{children}</p>
          </div>
        </Link>
      );
    }
    return (
      <a {...props} className={wrapperclasses} href={href} target={external ? '_blank' : undefined}>
        <div className={contentclasses}>
          <p className="color_text_2">{children}</p>
        </div>
      </a>
    );
  }

  if (asDiv) {
    return (
      <div {...props} className={wrapperclasses} onClick={onClick}>
        <div className={contentclasses}>
          <p className="color_text_2">{children}</p>
        </div>
      </div>
    );
  }

  return (
    <button {...props} disabled={disabled || loading} onClick={onClick} className={wrapperclasses}>
      <div className={contentclasses}>
        <p className="color_text_2">{children}</p>
      </div>
      {loading && <Spinner />}
    </button>
  );
};

export default OutLineButuon;
