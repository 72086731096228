import ConnectButton from 'components/header/ConnectButton';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import DemoVideo from './DemoVideo';
import LandingParagraph from './LandingParagraph';
import LandingSection from './LandingSection';

const HeroSection = () => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <LandingSection title={t('landing:hero_section.title')} size="h1">
      <LandingParagraph>{t('landing:hero_section.paragraph_1')}</LandingParagraph>
      <DemoVideo />
      <ConnectButton
        style="primary"
        size="md"
        className="mx-auto border-0 text-white  rounded-full bg-gradient-to-r hover:bg-gradient-to-b from-[#FF00FF] to-[#FE511B] px-6 transition-all duration-75 hover:bg-gradient-to-b hover:shadow-[0px_0px_5px_#FF00FF]"
        text={t('common:buttons.get_started')}
        redirect
      />
    </LandingSection>
  );
};

export default HeroSection;
