import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';

import { ReactNode } from 'react';

interface Props {
  question: string;
  children: ReactNode;
}

const FaqItem = ({ question, children }: Props) => {
  return (
    <Disclosure
      as="div"
      className="py-4 px-8 w-full bg-[#fff] dark:bg-[#060714] rounded-[11px] divide-y divide-[#504178]"
      property="mainEntity"
      typeof="Question"
    >
      {({ open }) => (
        <>
          <dt className="text-lg py-2">
            <Disclosure.Button className="flex gap-2 w-full items-center justify-between text-left">
              <h2 className="text-base sm:text-lg" property="name">
                {question}
              </h2>
              {open ? (
                <MinusIcon className={'h-6 w-6 transform shrink-0'} />
              ) : (
                <PlusIcon className={'h-6 w-6 transform shrink-0'} />
              )}
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel
            as="dd"
            className="mt-2 text-base text-zinc-700 dark:text-[#8F9BB7] py-3"
            unmount={false}
            property="acceptedAnswer"
            typeof="Answer"
          >
            <div property="text">{children}</div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
export default FaqItem;
