import type { NextSeoProps } from 'next-seo';

// For some reason next-seo's DefaultSeo isn't working, so I'll do it like this
export const defaultSEO: NextSeoProps = {
  // title + description are included through next-translate
  titleTemplate: '%s',
  openGraph: {
    url: 'https://banditproof.org/',
    images: [
      {
        url: 'https://banditproof.org/assets/images/logo.png',
        width: 1000,
        height: 24,
      },
    ],
    site_name: 'Banditproof',
    type: 'website',
  },
  twitter: {
    handle: '@HEXTOYSOFFICIAL',
    site: '@HEXTOYSOFFICIAL',
    cardType: 'summary_large_image',
  },
  additionalLinkTags: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: '/favicon.ico',
    },
    { rel: 'apple-touch-icon', href: '/assets/images/logo.png' },
    { rel: 'manifest', href: '/manifest.json' },
  ],
  additionalMetaTags: [{ name: 'theme-color' as const, content: '#000000' }],
};
