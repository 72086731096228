import ExplanationBubble from './ExplanationBubble';
import FullWidthLandingSection from './FullWidthLandingSection';

const HowTo = () => {
  return (
    <FullWidthLandingSection inverted>
      <div className="flex flex-col md:flex-row gap-10 pt-12">
        <ExplanationBubble
          title={'Connect'}
          icon={<img src="/assets/images/icons/icon_connect.svg" alt="" className="mb-4" />}
          bg_img="base04"
        >
          <p className="text-[#00000099] dark:text-[#8F9BB7] text-lg">
            Click Connect Wallet on the top right or enter an address in the search bar.
          </p>
        </ExplanationBubble>

        <ExplanationBubble
          title={'Inspect'}
          icon={<img src="/assets/images/icons/icon_message.svg" alt="" className="mb-4" />}
          bg_img="base06"
        >
          <p className="text-[#00000099] dark:text-[#8F9BB7] text-lg">
            Inspect your approvals by using the network selection, sorting and filtering options.
          </p>
        </ExplanationBubble>
        <ExplanationBubble
          title={'Revoke'}
          icon={<img src="/assets/images/icons/icon_revoke.svg" alt="" className="mb-4" />}
          bg_img="base03"
        >
          <p className="text-[#00000099] dark:text-[#8F9BB7] text-lg">
            Revoke the approvals that you no longer use to prevent unwanted access to your funds.
          </p>
        </ExplanationBubble>
      </div>
    </FullWidthLandingSection>
  );
};

export default HowTo;
