import { useColorTheme } from 'lib/hooks/useColorTheme';
import LandingParagraph from './LandingParagraph';

interface Props {
  title: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  bg_img?: string;
}

const ExplanationBubble = ({ title, icon, children, bg_img }: Props) => {
  const { darkMode } = useColorTheme();
  return (
    <div
      className={`flex-col items-start gap-2 text-zinc-900  dark:text-zinc-100 rounded-lg py-12 px-6 max-w-xs md:basis-96 relative `}
    >
      {darkMode ? (
        <img src={`/assets/images/${bg_img}.png`} alt="" className="absolute w-full h-full top-0 left-0" />
      ) : (
        <img src={`/assets/images/${bg_img}_white.png`} alt="" className="absolute w-full h-full top-0 left-0" />
      )}
      {bg_img === 'base04' ? (
        <div
          style={{
            backgroundImage: 'radial-gradient(#420e88aa, #907CFF00)',
            width: '70%',
            height: '90%',
            borderRadius: '50%',
            position: 'absolute',
            filter: 'blur(50px)',
            right: 0,
            top: 0,
            zIndex: 1,
            opacity: darkMode ? 1 : 0.1,
          }}
        ></div>
      ) : bg_img === 'base06' ? (
        <div
          style={{
            backgroundImage: 'radial-gradient(#4f1071aa, #907CFF00)',
            width: '70%',
            height: '90%',
            borderRadius: '50%',
            position: 'absolute',
            filter: 'blur(50px)',
            right: 0,
            top: 0,
            zIndex: 1,
            opacity: darkMode ? 1 : 0.1,
          }}
        ></div>
      ) : (
        <div
          style={{
            backgroundImage: 'radial-gradient(#601253aa, #907CFF00)',
            width: '70%',
            height: '90%',
            borderRadius: '50%',
            position: 'absolute',
            filter: 'blur(50px)',
            right: 0,
            top: 0,
            zIndex: 1,
            opacity: darkMode ? 1 : 0.1,
          }}
        ></div>
      )}

      {icon && <div className="relative z-[1]">{icon}</div>}
      <LandingParagraph title={title}>
        <div className="text-sm text-left">{children}</div>
      </LandingParagraph>
    </div>
  );
};

export default ExplanationBubble;
