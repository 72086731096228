import { ReactNode } from 'react';

interface Props {
  title?: string;
  children: ReactNode;
}

const LandingParagraph = ({ title, children }: Props) => {
  return (
    <div>
      {title && <h3 className="text-2xl mb-4 text-black dark:text-white relative">{title}</h3>}
      <div className="text-[#00000099] dark:text-[#8F9BB7] mb-4 text-center relative">{children}</div>
    </div>
  );
};

export default LandingParagraph;
