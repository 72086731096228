import { ReactNode } from 'react';

interface Props {
  title?: string;
  size: 'h1' | 'h2';
  children: ReactNode;
}

const LandingSection = ({ title, size, children }: Props) => {
  // bg-clip-text bg-gradient-to-r from-[#ff00ff] via-[#823aa3] to-[#100295] dark:from-[#69eacb] dark:via-[#eaccf8] dark:to-[#6654f1]
  return (
    <div className="w-full px-4 py-10">
      <div className="flex flex-col gap-4 md:gap-4 max-w-3xl mx-auto">
        {title &&
          (size === 'h1' ? (
            <h1 className="text-4xl md:text-5xl mb-4 text-transparent color_text_1 text-center">{title}</h1>
          ) : (
            <h2 className="text-3xl md:text-4xl">{title}</h2>
          ))}
        <div className="flex flex-col gap-4">{children}</div>
      </div>
    </div>
  );
};

export default LandingSection;
