import FaqItem from 'components/faq/FaqItem';
import useTranslation from 'next-translate/useTranslation';
import LandingSection from './LandingSection';

const LandingPageFaq = () => {
  const { t } = useTranslation();

  return (
    <LandingSection title={'Frequently Asked Questions'} size="h1">
      <dl className="flex flex-col w-full">
        <div className="w-full bg-gradient-to-r from-[#504178] to-[#0F0A1D] mb-4 p-0.5 rounded-xl">
          <FaqItem question={'If I have an "Unlimited" approval, does that mean my whole wallet is at risk?'}>
            <p className="text-[#00000099] dark:text-[#8F9BB7] text-sm sm:text-base">
              No. An "unlimited" approval only applies to the token or NFT collection that you gave an approval for. So
              if you gave an unlimited approval for your DAI, then all your DAI may be at risk, but none of your USDC.
              Similarly an "unlimited" approval for your Bored Apes does not impact your Cool Cats.
            </p>
          </FaqItem>
        </div>
        <div className="w-full bg-gradient-to-r from-[#504178] to-[#0F0A1D] mb-4 p-0.5 rounded-xl">
          <FaqItem question={'Is it enough to "disconnect" my wallet instead of revoking approvals?'}>
            <p className="text-[#00000099] dark:text-[#8F9BB7] text-sm sm:text-base">
              No. Disconnecting your wallet (e.g. MetaMask) does not do anything to protect you from approval exploits -
              or most other exploits. The only thing that happens when disconnecting your wallet from a website is that
              that website cannot see your address anymore. But your approvals stay active.
            </p>
          </FaqItem>
        </div>
        <div className="w-full bg-gradient-to-r from-[#504178] to-[#0F0A1D] mb-4 p-0.5 rounded-xl">
          <FaqItem question={'My funds were just stolen, can I use Banditproof to get them back?'}>
            <p className="text-[#00000099] dark:text-[#8F9BB7] text-sm sm:text-base">
              No. Banditproof is a preventative tool that helps you practice proper wallet hygiene. By regularly
              revoking active approvals you reduce the chances of becoming the victim of approval exploits. But
              unfortunately it cannot be used to recover any stolen funds. You should still make sure to revoke the
              approvals that were used to take your funds so that they cannot steal more in the future.
            </p>
          </FaqItem>
        </div>
        <div className="w-full bg-gradient-to-r from-[#504178] to-[#0F0A1D] mb-4 p-0.5 rounded-xl">
          <FaqItem question={'I want to revoke approvals, but when I add PLS to my account it gets stolen.'}>
            <p className="text-[#00000099] dark:text-[#8F9BB7] text-sm sm:text-base">
              If you have a so-called "sweeper bot" on your account that steals any PLS as soon as it comes in, your
              seed phrase was compromised. This means that revoking approvals is not going to help with your wallet
              security. Unfortunately, there is no way for your wallet to recover from this. You should abandon this
              wallet and create a new one.
            </p>
          </FaqItem>
        </div>
      </dl>
    </LandingSection>
  );
};

export default LandingPageFaq;
